import React from "react";
import quoteImg from "../../images/misc/quote.png";
import "./TestimonialItem.scss";

const TestimonialItem = ({ testimonial }) => {
  return (
    <div className="testimonial-item">
      <img src={quoteImg} className="quote" alt="quote" />
      <p className="testimonial-description">{testimonial?.description}</p>
      <h5 className="testimonial-client">{testimonial?.client}</h5>
    </div>
  );
};

export default TestimonialItem;
