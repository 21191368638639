/* eslint-disable import/no-anonymous-default-export */
export default {
  // TODO: See if we can remove paramaterized queries and pass values in object body
  API: {
    AVA: {
      ADD_SITE_VIEW: "/ava/AddSiteView?domain={domain}",
      GET_SITE_VIEW_COUNT: "/ava/GetSiteViewCount?domain={domain}",
      SUBMIT_CONTACT_FORM: "/ava/SubmitContactForm",
      SUBMIT_HELP_REQUEST: "/ava/SubmitHelpRequest",
    },
    EVENTS: {
      ADD_EVENT: "/event/AddEvent",
      DELETE_EVENT: "/event/DeleteEvent?id={id}",
      GET_ALL_EVENTS: "/event/GetAllEvents",
      GET_TOTAL_EVENT_COUNT: "/event/GetTotalEventCount",
    },
    PHOTO_GALLERY: {
      GET_TOTAL_PHOTO_COUNT: "/photogallery/GetTotalPhotoCount",
    },
    TESTIMONIALS: {
      ADD_TESTIMONIAL: "/testimonial/AddTestimonial",
      DELETE_TESTIMONIAL: "/testimonial/DeleteTestimonial?id={id}",
      GET_ALL_TESTIMONIALS: "/testimonial/GetAllTestimonials",
      GET_TOTAL_TESTIMONIAL_COUNT: "/testimonial/GetTotalTestimonialCount",
    },
    USERS: {
      ADD_USER: "/users/AddUser",
      CHANGE_PASSWORD:
        "/users/ChangePassword?email={email}&currentPassword={currentPassword}&newPassword={newPassword}",
      DELETE_USER: "/users/DeleteUser?email={email}",
      GENERATE_TEMPORARY_PASSWORD:
        "/users/GenerateTemporaryPassword?email={email}",
      GET_ALL_USERS: "/users/GetAllUsers",
      GET_TOTAL_USER_COUNT: "/users/GetTotalUserCount",
      GET_USER_BY_EMAIL: "/users/GetUserByEmail?email={email}",
      LOGIN: "/users/Login?email={email}&password={password}",
      RESET_PASSWORD: "/users/ResetPassword?email={email}&password={password}",
      UPDATE_USER_PROFILE:
        "/users/UpdateUserProfile?email={email}&firstName={firstName}&lastName={lastName}&position={position}&profilePic={profilePic}",
    },
  },
};
