import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import React from "react";
import { isMobile } from "react-device-detect";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import "./AboutUs.scss";

const AboutUs = () => {
  const aboutUsStrings = GeneralStrings.ABOUT_US;
  const services = aboutUsStrings.SERVICES;

  return (
    <div className="about-us">
      <div className="container section-container">
        <div className="row">
          <div className="col-md-12 col-lg-6 margin-vertical-center description-container">
            <div className="description-item-container">
              <span className="header cursive">
                {aboutUsStrings.OUR_MISSION.TITLE}
              </span>
              <p className="description">
                {aboutUsStrings.OUR_MISSION.DESCRIPTION}
              </p>
            </div>
            <div className="description-item-container">
              <span className="header cursive">
                {aboutUsStrings.OUR_VISION.TITLE}
              </span>
              <p className="description">
                {aboutUsStrings.OUR_VISION.DESCRIPTION}
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 image-container">
            <Card>
              <CardCover>
                <video className="our-mission-video" controls>
                  <source
                    src="https://floraldesignlab.s3.us-east-2.amazonaws.com/videos/Floral+Design_Final.mp4"
                    type="video/mp4"
                  />
                </video>
              </CardCover>
            </Card>
          </div>
        </div>
      </div>
      {isMobile && <hr />}
      <div className="container text-center services">
        <span className="section-header cursive">
          {aboutUsStrings.OUR_SERVICES}
        </span>
        <div className="row text-center services-container">
          {services.map((service) => (
            <div
              className="col-md-4 service-item"
              key={`service-${service.title}`}
            >
              <div className="">
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x service-item-icon"></i>
                  <i
                    {...classNames(
                      "fa-solid fa-stack-1x fa-inverse",
                      service.icon
                    )}
                  ></i>
                </span>
                <h4 className="service-title margin-top-20 margin-bottom-20 cursive">
                  {service.title}
                </h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
