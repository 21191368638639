import _ from "lodash";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialService from "../../services/TestimonialService";
import TestimonialItem from "../TestimonialItem/TestimonialItem";
import "./Testimonials.scss";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState();

  useEffect(() => {
    TestimonialService.getAllTestimonials().then((response) => {
      const testimonials = response?.data;
      setTestimonials(testimonials);
    });
  }, []);

  return (
    <section className="testimonials">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Swiper
              autoplay={{ delay: 5000 }}
              modules={[Autoplay, Pagination, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop
            >
              {!_.isEmpty(testimonials) &&
                testimonials.map((testimonial) => (
                  <SwiperSlide key={"testimonial-" + testimonial.id}>
                    <TestimonialItem testimonial={testimonial} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
