/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class TestimonialService {
  /**
   * [POST]
   * Submits the new testimonial to the database
   * @param {String} client
   * @param {String} description
   * @returns {Promise}
   */
  addTestimonial({ client, description }) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.TESTIMONIALS.ADD_TESTIMONIAL,
      headers: {},
      data: { client, description },
    });
  }

  /**
   * [DELETE]
   * Deletes testimonial from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteTestimonial(id) {
    return axios({
      method: "DELETE",
      url:
        getAPIUrl() +
        EndpointsConst.API.TESTIMONIALS.DELETE_TESTIMONIAL.replace("{id}", id),
      headers: {},
    });
  }

  /**
   * [GET]
   * Gets all testimonial
   * @returns {Promise}
   */
  getAllTestimonials() {
    /* return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.TESTIMONIALS.GET_ALL_TESTIMONIALS,
      headers: {},
    }); */
    let url =
      getAPIUrl() + EndpointsConst.API.TESTIMONIALS.GET_ALL_TESTIMONIALS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves total testimonial count
   * @returns {Promise}
   */
  getTotalTestimonialCount() {
    return axios({
      method: "GET",
      url:
        getAPIUrl() +
        EndpointsConst.API.TESTIMONIALS.GET_TOTAL_TESTIMONIAL_COUNT,
      headers: {},
    });
  }
}

export default new TestimonialService();
